@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  background: url("./assets/bg-desktop.jpg");
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Bebas Neue", sans-serif;
  overflow-x: hidden;
  z-index: 0;
}

@media (min-width: 200px) and (max-width: 667px) {
  body {
    background: url("./assets/bg-mob.ccb63578.webp");
    padding: 0;
    background-size: cover;
  }
}


 .footersec{
  bottom: -400;
 }

 