.customerFont {
  font-family: "Inknut Antiqua", serif;
  font-weight: 900;
}

@media (min-width: 200px) and (max-width: 667px) {
  .mobImg {
    width: 106px;
    height: 80px;
  }

  .customerMarnginMob {
    margin-top: 350px;
    position: relative;
  }

  .customerMobMargin {
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -500px;
  }
  #favSports {
    margin-top: 550px;
  }

  #mobmargin {
    margin-top: 20px;
  }
}

@media (min-width: 670px) and (max-width: 1500px) and (orientation: portrait) {
  .deskLogo {
    width: 200px;
    height: 100px;
    background-size: contain;
  }

  .deskImg {
    width: 50px;
    height: 50px;
    background-repeat: none;
  }

  .player {
    margin-top: 100px;
    background-color: #94949447;
  }
}

.deskmargin {
  background: #000;
  margin-top: 400px;
  margin-bottom: 0;
}

.whatsapp-icon {
  width: 90px;
  height: 90px;
  animation: whatsapp-zoom-in 1s infinite alternate; /* Continuous animation */
}

@keyframes whatsapp-zoom-in {
  from {
    transform: scale(1);  
  }
  to {
    transform: scale(1.2);  
  }
}